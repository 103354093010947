<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="updateCategory">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Category Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model="form.title"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Category Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model="form.slug"
                        placeholder="Enter category slug"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                  >
                    Update Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
export default {
  name: "update-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Update Category",
      form: {
        id: this.$route.params.id,
        title: null,
        slug: null,
      },
    };
  },
  mounted() {
    this.singleCategory();
  },
  methods: {
    singleCategory() {
      this.$axios
        .get("categories/" + this.form.id)
        .then((response) => {
          this.form.title = response.data.title;
          this.form.slug = response.data.slug;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateCategory() {
      this.$axios
        .put("categories/" + this.form.id, this.form)
        .then((response) => {
          this.$router.push({
            name: "category",
          });
          this.triggerSwal(response.data.message, "success");
          console.log(response.data);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.log(error.response);
        });
    },
  },
};
</script>